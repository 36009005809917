import { render, staticRenderFns } from "./PortfolioSecondaryKpi.vue?vue&type=template&id=e2aa7760&scoped=true&"
import script from "./PortfolioSecondaryKpi.vue?vue&type=script&lang=js&"
export * from "./PortfolioSecondaryKpi.vue?vue&type=script&lang=js&"
import style0 from "./PortfolioSecondaryKpi.vue?vue&type=style&index=0&id=e2aa7760&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2aa7760",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Alert: require('/builds/spatz/ui/web/spatz-app/components/Alert.vue').default})
