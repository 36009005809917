
    import Alert from "@/components/Alert";
import PortfolioAssetTypeSelector from "@/components/portfolio/asset/PortfolioAssetTypeSelector";
import IntroductionFuncionalityPortfolioModal from "@/components/portfolio/create/IntroductionFuncionalityPortfolioModal";
import CustomPortfolioIcon, { UPLOAD_ICON_STATUSES } from "@/components/portfolio/CustomPortfolioIcon";
import FinApiDisclaimer from '@/components/portfolio/FinApiDisclaimer';
import ImportPortfolioPositions from '@/components/portfolio/ImportPortfolioPositions';
import LivePortfolioImport from '@/components/portfolio/LivePortfolioImport';
import { importStatus } from '@/constants/import';
import subscriptionConstants from '@/constants/subscription.constants';
import { backendExceptionHandler } from "@/mixins";
import PortfolioService from '@/service/PortfolioService';

    const { subscribeProductKeys } = subscriptionConstants

    const CREATE_PORTFOLIO_ICON_DARK_MODE = [
        require('@/assets/add_porfolio/dark/import-manually.svg'),
        require('@/assets/add_porfolio/dark/import-automatically.svg'),
        require('@/assets/add_porfolio/dark/create-manual.svg'),
        require('@/assets/add_porfolio/dark/create-other.svg'),
    ]

    const CREATE_PORTFOLIO_ICON_LIGHT_MODE = [
        require('@/assets/add_porfolio/light/import-manually.svg'),
        require('@/assets/add_porfolio/light/import-automatically.svg'),
        require('@/assets/add_porfolio/light/create-manual.svg'),
        require('@/assets/add_porfolio/light/create-other.svg'),
    ]

    export default {
        name: "AddPortfolio",
        mixins: [ backendExceptionHandler ],
        components: {
            Alert,
            ImportPortfolioPositions,
            LivePortfolioImport,
            FinApiDisclaimer,
            PortfolioAssetTypeSelector,
            IntroductionFuncionalityPortfolioModal,
            CustomPortfolioIcon
        },
        data() {
            return {
                isVisible: false,
                loading: false,
                portfolioName: null,
                portfolioId: null,
                errorMessage: null,
                nameErrors: null,
                webFormUrl: null,
                fileImportStatus: false,
                uploadIconStatus: UPLOAD_ICON_STATUSES.none,
                uploadIconStatuses: UPLOAD_ICON_STATUSES,
                step: 1,
                selectedCreateType: null,
                showTooltip: false,
                targetBank: null,
                connectedBanks: [],
                assetGroupKey: null,
                portfolioIconKey: '',
                portfolioCustomIconId: null,
                uploadedIcons: [],
                selectedCustomIconId: null
            }
        },

        computed: {
            finApiEnabled() {
                return this.$store.state.account.subscribedProductIds?.includes(subscribeProductKeys.finApi)
            },
            finApiToken() {
                return this.webFormUrl?.substr(this.webFormUrl.lastIndexOf('/') + 1)
            },
            finapiUrl() {
                if (!this.webFormUrl) {
                    return null
                }
                const pathArray =this.webFormUrl.split( '/' );
                const protocol = pathArray[0];
                const host = pathArray[2];
                return protocol + '//' + host;
            },
            dialogWidth() {
                if (!this.$vuetify.breakpoint.mdAndUp) {
                    return '100vw'
                }
                if (this.step < 3 || this.selectedCreateType != this.CREATE_TYPE_FILE) {
                    return 630
                }
                if (!this.fileImportStatus || [importStatus.empty, importStatus.uploading ].includes(this.fileImportStatus)) {
                    return 800
                }
                return null
            },
            individualAssetEnabled() {
                return this.$store.state.account.subscribedProductIds?.includes(subscribeProductKeys.individualAsset)
            },
            createTypes() {
                const types = []
                types[this.CREATE_TYPE_FINAPI] = {
                    title: this.$t('component.portfolio.addPortfolio.importPositionsAutomatically'),
                    description: this.$t('component.portfolio.addPortfolio.importPositionsAutomaticallyDescription'),
                    hide: this.$store.state.preferences.isSandboxMode,
                }
                types[this.CREATE_TYPE_FILE] = {
                    title: this.$t('component.portfolio.addPortfolio.importPositionsManually'),
                    description: this.$t('component.portfolio.addPortfolio.importPositionsManuallyDescription'),
                }
                types[this.CREATE_TYPE_MANUAL] = {
                    title: this.$t('component.portfolio.addPortfolio.createPortfolioManually'),
                    description: this.$t('component.portfolio.addPortfolio.createPortfolioManuallyDescription'),
                }

                types[this.CREATE_TYPE_ASSET] = {
                    title: this.$t('component.portfolio.addPortfolio.createPortfolioForOtherAssets'),
                    description: this.$t('component.portfolio.addPortfolio.createPortfolioForOtherAssetsDescription'),
                }

                return types
            },
            imageCreatePortfolio() {
                return this.$vuetify.theme.dark ? CREATE_PORTFOLIO_ICON_DARK_MODE : CREATE_PORTFOLIO_ICON_LIGHT_MODE
            },
            rightArrowIcon() {
                return this.$vuetify.theme.dark ? 
                    require('@/assets/add_porfolio/dark/right-icon.svg') :
                    require('@/assets/add_porfolio/light/right-icon.svg')
            },
            disabledBackButtonSecondStep() {
                return this.uploadIconStatus === UPLOAD_ICON_STATUSES.uploading
            },
            disabledBackButton() {
                if (this.step == 2) {
                    return this.disabledBackButtonSecondStep
                }

                return false
            },
            showBackButton() {
                return this.step != 1
            },
            dialogTitle() {
                if (this.step == 1) {
                    return  this.$t('component.portfolio.addPortfolio.newPortfolio')
                }

                if (this.step == 2) {
                    return this.uploadIconStatus === UPLOAD_ICON_STATUSES.none ?
                        this.$t('component.portfolio.addPortfolio.portfolioName') :
                        this.$t('component.portfolio.addPortfolio.uploadIcon')
                }

                if (this.step == 3) {
                    if(this.selectedCreateType == this.CREATE_TYPE_FILE) {
                        return this.$t('component.portfolio.addPortfolio.importPositions')
                    }
                    if (this.selectedCreateType == this.CREATE_TYPE_FINAPI) {
                        return this.$t('component.portfolio.addPortfolio.linkedWithFinApi')
                    }
                    if (this.selectedCreateType == this.CREATE_TYPE_ASSET) {
                        return this.$t('component.portfolio.addPortfolio.chooseAssetType')
                    }
                }

                // step 4
                return this.$t('component.portfolio.addPortfolio.importWithFinApi')
            },
            importPortfolioVisible() {
                return this.visible
                    && this.selectedCreateType === this.CREATE_TYPE_FILE
                    && this.step == 3
            }
        },

        watch: {
            isVisible(visible) {
                if (!visible &&
                    this.$refs.fileImport &&
                    this.selectedCreateType === this.CREATE_TYPE_FILE &&
                    this.step == 3
                ) {
                    this.$refs.fileImport.$emit('reset')
                }
            },
            '$store.state.preferences.isSandboxMode': {
                handler(newState) {
                    this.portfolioService.setSandboxMode(newState)
                }
            }
        },

        methods: {
            openWindow() {
                this.showTooltip = false;
                this.$gtag?.event(
                    'sp_subscr_feature_finapi_required',
                    { value: 1 },
                )
                this.portfolioName = null
                this.portfolioId = null
                this.webFormUrl = null
                this.$store.commit('snackbar/updateMessage', '')
                this.reset()
                this.$nextTick(() => this.isVisible = true)
                setTimeout(() =>{
                    this.showTooltip = true;
                    this.$refs.buttonIntro[0].$el.blur();
                }, 0)
            },

            async doStep(arg = null) {
                this.errorMessage = null
                if (this.step == 1) {
                    if (arg == this.CREATE_TYPE_FINAPI && !this.finApiEnabled) {
                        this.$router.push({ name: this.$getTranslatedRouteName('feature-portfolio-automatisch-importieren') })
                        if (this.$route.name === this.$getTranslatedRouteName('feature-portfolio-automatisch-importieren')) {
                            this.errorMessage = this.$t('component.portfolio.addPortfolio.subscriptionRequired')
                        }
                        return
                    } else if (arg == this.CREATE_TYPE_ASSET && !this.individualAssetEnabled) {
                        this.$router.push({ name: this.$getTranslatedRouteName('feature-individuelle-assets') })
                        if (this.$route.name === this.$getTranslatedRouteName('feature-individuelle-assets')) {
                            this.errorMessage = this.$t('component.portfolio.addPortfolio.subscriptionRequired')
                        }
                        return
                    }
                    this.selectedCreateType = arg
                    if (this.selectedCreateType == this.CREATE_TYPE_FINAPI) {
                        // array<{ bankName: String, finApiConnectionId: Integer }>
                        this.connectedBanks = []
                        this.loading = true
                        this
                            .portfolioService
                            .getConnectedBanks(this.portfolioName)
                            .then(data => {
                                this.connectedBanks = data
                                this.connectedBanks.unshift({
                                    bankName:  this.$t('component.portfolio.addPortfolio.createNewBankConnection'),
                                    id: null,
                                })
                                setTimeout(
                                    () => this.$refs.portfolioNameInput.$refs.input.focus()
                                )
                            })
                            .finally(() => {
                                this.loading = false
                            })
                    }
                } else if (this.step == 2) {
                    this.$refs.fileImport?.$emit('reset')
                    if (this.portfolioId) {
                        this.step++
                        return
                    }
                    const validation = this.validatePortfolioName()
                    if (validation != true) {
                        this.nameErrors = validation
                        this.$refs.portfolioNameInput.focus()
                        return
                    }

                    // asset type create portfolio
                    if (this.selectedCreateType == this.CREATE_TYPE_ASSET) {
                        this.step++
                        return
                    }

                    this.loading = true
                    try {
                        if (this.selectedCreateType == this.CREATE_TYPE_FILE) {
                            await this.createPortfolio()
                        } else if (this.selectedCreateType == this.CREATE_TYPE_MANUAL) {
                            await this.createPortfolio()
                            this.redirectToNewPortfolio(this.$t('component.portfolio.addPortfolio.portfolioCreatedSuccess'))
                            await this.getPortfolioTabs()
                            return  // manual creation, all done
                        }
                    } catch (error) {
                        console.log(error)
                        this.handleBackendException(
                            error, this.$t('component.portfolio.addPortfolio.portfolioCreationError'))
                        return
                    } finally {
                        this.loading = false
                    }
                } else if (this.step == 3 && this.selectedCreateType == this.CREATE_TYPE_FINAPI) {
                    if (!this.finApiToken) {
                        this.loading = true
                        try {
                            await this.importLivePortfolio()
                        } catch (error) {
                            console.log(error)
                            this.handleBackendException(error, this.$t('component.portfolio.addPortfolio.generalError'))
                            this.step--
                            return
                        } finally {
                            this.loading = false
                        }
                    }
                }
                this.step++
            },

            nameChanged() {
                this.nameErrors = null
            },

            async importLivePortfolio() {
                let webForm
                try {
                    if (this.targetBank) {
                        webForm = await this
                            .portfolioService
                            .updateIntoPortfolio(
                                this.portfolioName,
                                this.targetBank,
                            )
                    } else {
                        webForm = await this
                            .portfolioService
                            .startFinBankConnectionImport(this.portfolioName)
                    }
                    if (!webForm) {
                        throw new Error('Expected webform was not returned')
                    }
                } catch (e) {
                    console.log(e)
                    this.step -= 2
                    this.handleBackendException(e, this.$t('component.portfolio.addPortfolio.generalError'))

                    return
                }
                this.portfolioId = webForm.portfolioId.toString(),
                this.webFormUrl = webForm.url
                this.$root.$emit('request-portfolio-tabs-refresh')
            },

            redirectToNewPortfolio(flash = '') {
                this.isVisible = false
                this.$router.push({
                    name: this.$getTranslatedRouteName('finance-portfolio-portfolio_id-tab_id'),
                    params: {
                        portfolio_id: this.portfolioId.toString(),
                        flash,
                        tab_id: this.$getTranslatedTabId('stocks'),
                    }
                })
            },

            reset() {
                this.errorMessage = null
                this.nameErrors = null
                this.step = 1
                this.uploadIconStatus = UPLOAD_ICON_STATUSES.none
                this.uploadedIcons = []
                this.selectedCustomIconId = null
            },

            validatePortfolioName() {
                if (!this.portfolioName) {
                    return this.$t('component.portfolio.addPortfolio.emptyPortfolioName')
                }
                if (this.portfolioName.length > 50){
                    return this.$t('component.portfolio.addPortfolio.nameTooLong')
                }
                if (this.portfolioName.replaceAll(' ','') == ''){
                    return this.$t('component.portfolio.addPortfolio.nameOnlySpaces')
                }
                return true;
            },

            async uploadCustomIcon() {
                if (!this.selectedCustomIconId || !this.uploadedIcons.length) {
                    return
                }

                const iconToUpload = this.uploadedIcons.find(icon => icon.id === this.selectedCustomIconId)
                const res = await this.portfolioService.uploadIcon(iconToUpload.file)
                this.portfolioCustomIconId = res.data[0].id
            },

            async createPortfolio() {
                await this.uploadCustomIcon()

                const apiEndpoint = this.$store.state.preferences.isSandboxMode ? '/api/portfolio?context=virtual' : '/api/portfolio'
                await this
                    .$axios
                    .post(apiEndpoint, {
                        name: this.portfolioName,
                        icon: this.portfolioIconKey,
                        customIconId: this.portfolioCustomIconId
                    })
                    .then(response => {
                        this.portfolioId = response.data.id
                        this.$root.$emit('request-portfolio-tabs-refresh')
                        this.$gtag?.event('sp_portfolio_create', {value: 1})
                        this.$store.commit('snackbar/updateType', 'success')
                        this.$store.commit('snackbar/updateMessage', this.$t('component.portfolio.addPortfolio.createSuccess'))
                    })
            },

            async fileImportStatusUpdate(status) {
                this.fileImportStatus = status
                if (status == 'success') {
                    this.redirectToNewPortfolio(this.$t('component.portfolio.addPortfolio.filesImportedSuccess'))
                    await this.getPortfolioTabs()
                } else {
                    console.log('fileImportStatus:', status)
                }
            },

            handleClickBackButton() {
                if (this.disabledBackButton) {
                    return
                }

                if (this.step == 2) {
                    this.handleClickBackButtonSecondStep()
                    return
                }

                this.step -= 1
            },

            handleClickBackButtonSecondStep() {
                if (this.uploadIconStatus !== UPLOAD_ICON_STATUSES.none) {
                    this.uploadIconStatus =  UPLOAD_ICON_STATUSES.none
                    return
                }

                this.reset()
            },

            async liveImportStatusUpdate(status) {
                this.isVisible = false
                if (status != 'completed') {
                    console.log('finApiImportStatus:', status)
                    this.redirectToNewPortfolio()
                    return
                }
                this.$gtag?.event('sp_portfolio_finapi_webform_success', { value: 1 })
                this.redirectToNewPortfolio([
                    this.$t('component.portfolio.addPortfolio.transactionImportMessage'),

                ].join(''))
                await this.getPortfolioTabs()
            },
            updateErrorMessage() {
                this.errorMessage = '';
            },

            showModalIntroduce(e, index) {
                e.stopPropagation()                
                this.isVisible = false;
                this.$root.$emit('portfolio-intro-open', index);
            },

            async getPortfolioTabs() {
                try {
                    this.portfolios = await this.portfolioService.getTabs()
                } catch(e) {
                    this.handleBackendException(e, this.$t('component.portfolio.addPortfolio.portfolioTabsError'))
                }

                const ownPortfolios = this.portfolios.filter(p => p.portfolioType == 'regular' || p.portfolioType == 'asset')
                if (ownPortfolios.length === 1) {
                    const isAssetPortfolio = ownPortfolios[0].portfolioType === 'asset'
                    this.$root.$emit('request-introduction-functionality-portfolio-dialog', isAssetPortfolio)
                }

            },

            onKeyDown(e) {
                if (e.key === 'Escape') {
                    this.isVisible = false
                }
            },

            
            async submitAssetPortfolio() {
                if(!this.assetGroupKey) {
                    this.errorMessage = this.$t('component.portfolio.addPortfolio.selectPortfolioTypeError');
                    return;
                }
                this.errorMessage = null;
                this.loading = true;
                try{
                    await this.uploadCustomIcon()

                    const data = {
                        name: this.portfolioName,
                        assetGroupKey: this.assetGroupKey,
                        icon: this.portfolioIconKey,
                        customIconId: this.portfolioCustomIconId
                    }
                    const response = await this.portfolioService.createAssetPortfolio(data);
                    this.loading = false;
                    this.portfolioId = response.id;
                    this.$root.$emit('request-portfolio-tabs-refresh');
                    this.redirectToNewPortfolio('', 'overview');
                    await this.getPortfolioTabs()
                } catch (error) {
                    this.handleBackendException(error, this.$t('component.portfolio.addPortfolio.portfolioCreationError'))
                } finally {
                    this.loading = false;
                }
            },
        },

        created() {
            this.importStatus = importStatus
            this.portfolioService = new PortfolioService(this.$axios, this.$store.state.preferences.isSandboxMode)
            this.CREATE_TYPE_FILE = 0
            this.CREATE_TYPE_FINAPI = 1
            this.CREATE_TYPE_MANUAL = 2
            this.CREATE_TYPE_ASSET = 3
        },

        beforeMount() {
            this.$root.$on('request-add-portfolio-dialog', this.openWindow)
            window.addEventListener('keydown', this.onKeyDown)
        },

        beforeDestroy() {
            this.$root.$off('request-add-portfolio-dialog', this.openWindow)
            window.removeEventListener('keydown', this.onKeyDown)
        }
    }
