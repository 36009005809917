
    import { backendExceptionHandler, privacyModeTextFormatterMixin } from "@/mixins";
import FormatterService from "@/service/FormatterService";
import { sortHandler, companyLogoHandler } from "../mixins";
import CdnService from "../service/CdnService";
import Alert from "./Alert";

    const NO_RESULT_FACETS = ['ALLE', 'AKTIE', 'ETF', 'ETC', 'FONDS', 'WÄHRUNG', 'CRYPTO', 'INDEX', 'PORTFOLIO'].reduce((map, obj) => {
        map[Object.keys(map).length] = {
            cnt: 0,
            instrumentTypeId: obj,
            name: obj,
        }
        return map
    }, {})

    export default {
        name: "Searchbar",
        mixins: [backendExceptionHandler, sortHandler, privacyModeTextFormatterMixin, companyLogoHandler],
        components: {Alert},
        props: {
            clearable: {
              type: Boolean,
              default: true
            },
            bus: {
                type: Object,
                default: null
            },
            addPositionMode: {
                type: Boolean,
                default: false
            },
            blacklist: {
                type: Array,
                default: () => []
            },
            instrumentTypeSet: {
                type: String,
                default: "default"
            },
            positionRelative: {
              type: Boolean,
              default: false
            },
            hideIcon: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            placeholder() {
                return this.bus
                    ? this.$t('component.searchbar.placeholder.default')
                    : this.$t('component.searchbar.placeholder');
            },
            widgetHeaders() {
                return [
                    {text: this.$t('component.searchbar.widgetHeaders.name'), value: 'name'},
                    {text: this.$t('component.searchbar.widgetHeaders.isin'), value: 'isin', class: 'hidden-md-and-down sticky-header', hideCols: 'md-and-down', width: '150px'},
                    {text: this.$t('component.searchbar.widgetHeaders.instrumentType'), value: 'instrumentType', class: 'hidden-xs-only', hideCols: 'xs-only', width: '80px'},
                    {text: this.$t('component.searchbar.widgetHeaders.primaryMarketMic'), value: 'primaryMarketMic', class: 'hidden-xs-only', hideCols: 'xs-only', width: '125px'},
                    {text: this.$t('component.searchbar.widgetHeaders.price'), value: 'price', align: 'end', width: '197px'}
                ]
            }
        },

        data () {
            return {
                sortDesc: false,
                isLoading: false,
                items: null,
                search: '',
                activeSearchRequest: null,
                instrumentTypes: null,
                selectedInstrumentType: 0,
                errorMessage: null,
                isFocused: false,
                isLoadingQuotes: true,
                format: new FormatterService(),
                showFullSearchBox: false
            }
        },

        watch: {
            selectedInstrumentType() {
                this.querySelections()
            },
            search(newVal) {
                if(newVal === '') {
                    this.clear()
                }
            },
        },

        methods: {
            handleClickSearchIcon() {                
                this.showFullSearchBox = true
                this.isFocused = true
            },
            generateRouteLink(item) {
                let route = ''
                if (item.instrumentType === 'Asset' || item.instrumentTypeId === 21) {
                    route = ''
                } else if (item.instrumentType === 'Portfolio') {
                    route = { name: this.$getTranslatedRouteName('finance-portfolio-portfolio_id-tab_id'), params: { portfolio_id: item.portfolioId.toString() }}
                } else {
                    route = { name : this.$getTranslatedRouteName('aktie-share'), params: { share: item.slug }}
                }

                return route
            },
            focusAndTrigger() {
                this.showFullSearchBox = true;
                this.isFocused = true;
                this.search = ' ';

                this.$nextTick(() => {
                    const input = document.querySelector('input[data-role="searchbar"]');
                    if (input) {
                        this.querySelections();
                    }
                });
            },
            onShareSelected(selectedItem) {
                if(!selectedItem) return
                this.clear()

                if (this.bus) {
                    this.bus.$emit('searchbar-share-selected', selectedItem)
                    return
                }
                let route
                if (selectedItem.instrumentType === 'Portfolio') {
                    route = { name: this.$getTranslatedRouteName('finance-portfolio-portfolio_id-tab_id'), params: { portfolio_id: selectedItem.portfolioId.toString() }}
                } else {
                    route = { name : this.$getTranslatedRouteName('aktie-share'), params: { share: selectedItem.slug }}
                }
                this.$router.push(route)
                this.$gtag?.event('search', {search_term: this.search})
            },

            clear() {
                this.items = null
                this.search = null
                this.selectedInstrumentType = 0
                this.cancelActiveSearchRequest()
                this.isFocused = false
                this.showFullSearchBox = false
            },

            queryQuotes(isinList = []) {
                if (!isinList?.length) {
                    return
                }

                this.isLoadingQuotes = true

                const url = '/api/shares/facet-search-quotes'
                const data = {
                    isins: isinList
                }

                const axiosSource = this.$axios.CancelToken.source()
                const cancelToken = axiosSource.token
                this.activeSearchQuotesRequest = { cancel: axiosSource.cancel, msg: "Loading quotes..." }
    
                this
                    .$axios
                    .post(url, data, { cancelToken })
                    .then(response => {
                        this.activeSearchQuotesRequest = null;
                        const quotes = response?.data || []

                        // update the quote infor to items
                        this.items = this.items.map(c => {
                            const foundQuote = quotes.find(q => q.isin === c.isin) || {}
                            return {
                                ...c,
                                ...foundQuote
                            }
                        })

                        this.isLoadingQuotes = false
                    })
                    .catch(error => {
                        if (this.$axios.isCancel(error)) {
                            return
                        }
                        this.isLoadingQuotes = false
                        this.handleBackendException(error, 'Konnte die Suchergebnisse nicht abfragen')
                    })
            },

            querySelections (e) {
                if (e && e.key == 'Escape') {
                    this.clear()
                    e.target.blur()
                    return
                }
                if (!this.search) {
                    return
                }
                this.cancelActiveSearchRequest()
                this.isLoading = true

                const axiosSource = this.$axios.CancelToken.source();
                this.activeSearchRequest = { cancel: axiosSource.cancel, msg: "Loading..." };

                const url = '/api/shares/facet-search'
                const data = {
                    params: {
                        q: this.search,
                        instrumentTypeSet: this.instrumentTypeSet
                    },
                    cancelToken: axiosSource.token,
                }

                if (this.selectedInstrumentType) {
                    if (this.instrumentTypes[this.selectedInstrumentType]?.cnt === 0) {
                        this.items = []
                        this.isLoading = false
                        return
                    }
                    data.params.instrumentTypeId = this.selectedInstrumentType
                }

                this
                    .$axios
                    .get(url, data)
                    .then(response => {
                        this.activeSearchRequest = null;
                        this.items = response.data.data || []
                        this.instrumentTypes = response.data.facets || NO_RESULT_FACETS
                        this.isLoading = false
                        const isinList = this.items.map(c => c.isin)
                        this.queryQuotes(isinList)
                    })
                    .catch(error => {
                        if (this.$axios.isCancel(error)) {
                            return
                        }
                        this.isLoading = false
                        this.handleBackendException(error, 'Konnte die Suchergebnisse nicht abfragen')
                    })
            },

            cancelActiveSearchRequest() {
                if (this.activeSearchRequest) {
                    this.activeSearchRequest.cancel()
                    this.isLoading = false
                }
                // whenever cancel a search request, should cancel the active search quotes request also
                if (this.activeSearchQuotesRequest) {
                    this.activeSearchQuotesRequest.cancel()
                    this.isLoadingQuotes = false
                }
            },

            onWindowClick (e) {
                const input = document.querySelector('input[data-role="searchbar"]')

                if (e.target != input && !e.target.closest('.searchbar__widget') && !e.target.closest('.searchbar-mobile-icon')) {
                    this.isFocused = false
                    this.showFullSearchBox = false
                }

                if(!this.items) return

                const widget = document.getElementById('searchbar-widget')
                const widgetChildren = Array.prototype.slice.call(widget.getElementsByTagName("*"))
                // TODO: only copy relevant parameter
                if(widgetChildren.find(item => item === e.target)) return

                if (e.target != input) {
                  this.clear()
                }
            },

            isIsinOnBlacklist(isin) {
                return this.blacklist.find(item => item === isin) !== undefined
            },

            updateErrorMessage() {
                this.errorMessage = "";
            },

            getCompanyLogoUrl(logo) {
                return this.cdnService.getLogoUrl(logo);
            },
            getCompanyLogoClass(logo) {
                return (logo && logo != null) ? 'company-logo' : 'company-logo-invalid'
            },
        },

        created() {
            this.cdnService = new CdnService()
        },

        beforeMount() {
            window.addEventListener('click', this.onWindowClick)
        },

        beforeDestroy() {
            window.removeEventListener('click', this.onWindowClick);
        },

        updated() {
            const input = document.querySelector('input[data-role="searchbar"]')
            if (document.activeElement === input && !this.positionRelative) {
                this.isFocused = true;
            }
        }
    }
