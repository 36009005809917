
    export default {
        name: "Brand",

        computed: {
            logoPath() {
                return this.$vuetify.theme.dark ?
                    require('@/assets/money-peak-logos/money-peak-white-logo.png') :
                    require('@/assets/money-peak-logos/money-peak-black-logo.png');
            },
            logoMobilePath() {
                return this.$vuetify.theme.dark ?
                    require('@/assets/money-peak-full-logos/money-peak-full-white-logo-mobile.png') :
                    require('@/assets/money-peak-full-logos/money-peak-full-black-logo-mobile.png');
            },
            isFinAgentPage() {
                return this.$route.name == this.$getTranslatedRouteName('fin-agent')
            },
            homePath() {
                if (this.$store.state.account.user) {
                    return this.$getTranslatedRoutePath('/finance/dashboard/')
                }

                return this.$getTranslatedRoutePath('/login/')
            }
        },

        data() {
            return {
                routeName: 'index'
            }
        },
    }
