import routingUtils from "@/utils/routing.utils";
import localizationConfigs from '@/config/localization.config';

const { getTranslatedRouteName, getTranslatedRoutePath, isPublicRoute } = routingUtils
const { AVAILABLE_LOCALE_CODES, FALLBACK_LOCALE } = localizationConfigs;

export default function ({ store, route, redirect }) {
    if (process.server) {
        return
    }
    const routeLang = route.path.split('/')?.[1];
    let lang = FALLBACK_LOCALE
    if (AVAILABLE_LOCALE_CODES.includes(routeLang)) {
        lang = routeLang
    }
    const indexPageRouteName = getTranslatedRouteName('login', lang)

    if (store.state.account.user) {
        if (route.name == indexPageRouteName) {
            const dashboardPagePath = getTranslatedRoutePath('/finance/dashboard/', lang)
            return redirect({ path: dashboardPagePath })
        }
        return
    }
    if (!route.name || isPublicRoute(route, lang)) {
        return
    }
    return redirect({
        name: getTranslatedRouteName('login', lang),
    })
}
