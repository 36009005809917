
    import AuthMenu from "@/components/auth/Menu";
import MobileMenu from "@/components/header/MobileMenu";
import PageNavigation from "@/components/header/PageNavigation";
import LocaleSelector from "@/components/LocaleSelector";
import Searchbar from "@/components/Searchbar";
import HeaderSnackbar from '@/components/shared/SnackBar';
import Brand from "./Brand";
import PortfolioTabs from "./portfolio/PortfolioTabs";
import { backendExceptionHandler } from '@/mixins';
import FinAgentService from '@/service/FinAgentService';
import FinAgentProfileDialog from '@/components/finagent/FinAgentProfileDialog';

    export default {
        name: "SiteHeader",

        components: {
            Brand,
            AuthMenu,
            Searchbar,
            PortfolioTabs,
            PageNavigation,
            MobileMenu,
            LocaleSelector,
            HeaderSnackbar,
            FinAgentProfileDialog,
        },

        mixins: [ backendExceptionHandler ],

        data() {
            return {
                isLanguageModalOpen: false,
                availableLocales: this.$getAvailableLocales(),
                showSnackbar: false,
                isCreatingNewThread: false,
                isProfileDialogVisible: false
            }
        },
        computed: {
            inFeedbackPage() {
                return this.$route.name == this.$getTranslatedRouteName('feedback')
            },
            isLoggedIn() {
                return !!this.$store.state.account.user
            },
            portfolioId() {
                const financePortfolioRouteName = this.$getTranslatedRouteName('finance-portfolio')
                return this.$route.name?.indexOf(financePortfolioRouteName) !== -1
                    ? this.$route.params.portfolio_id
                    : '-1'
            },
            isMobile: {
                get() { return this.$vuetify.breakpoint.xsOnly },
            },
            isPrivacyEnabled() {
                return this.$store.state.preferences.isPrivacyEnabled
            },
            isSandboxMode() {
                return this.$store.state.preferences.isSandboxMode;
            },
            selectedLanguage() {
                const currentLocale = this.$store.getters['preferences/getLocale']
                return this.availableLocales.find(locale => locale.code === currentLocale)
            },
            snackbarMessage() {                
                return this.$store.state.snackbar.message;
            },
            showPortfolioTabs() {
                const originalPaths = ['settings', 'market-analysis', 'profile', 'fin-agent']
                const translatedPaths = originalPaths.map(path => this.$getTranslatedRoutePath(`/${path}/`))
                const currentPath = this.$route.path
                return !translatedPaths.some((path) => currentPath.includes(path))
            },
            isFinAgentPage() {
                return this.$route.name == this.$getTranslatedRouteName('fin-agent')
            },
            showFinAgentMobileElements() {
                return this.isFinAgentPage && this.$vuetify.breakpoint.smAndDown
            },
            isThreadsEmpty() {
                return !this.$store.state.finAgent.threads.length
            },
        },
        watch: {
            snackbarMessage: {
                immediate: true,
                handler(newState) {
                    if (newState) {
                        this.showSnackbar = true;
                    }
                }
            },
            showSnackbar: {
                immediate: true,
                handler(newState) {
                    if (!newState) {
                        this.$store.commit('snackbar/updateMessage', '');
                    }
                }
            },
        },
        methods: {
            handleTogglePrivacy() {
                this.$store.commit('preferences/setIsPrivacyEnabled', !this.isPrivacyEnabled);
            },
            handleSnackbarEvent(event) {
                if (!event) {
                    this.showSnackbar = false;
                }
            },
            async handleCreateNewThread() {
                try {
                    this.$store.commit('finAgent/setOpenThreadsMobile', false);
                    this.isCreatingNewThread = true;
                    const createdThread = await this.finAgentService.createThread();
                    this.messages = [];
                    this.$store.commit('finAgent/addThread', createdThread);
                    this.$store.commit('finAgent/setCurrentThread', createdThread);
                } catch (error) {
                    this.handleBackendException(error,this.$t('common.errors.generalError'));
                } finally {
                    this.isCreatingNewThread = false;
                }
            },
        },
        created() {
            this.$store.commit('snackbar/updateMessage', '');
            this.finAgentService = new FinAgentService(this.$axios);
        },
    }
