const initialCurrentThread = {
    title: '',
    id: null,
    created: null,
    updated: null
};

const initialState = {
    threads: [],
    currentThread: initialCurrentThread,
    isThreadsLoading: true,
    isOpenThreadsMobile: false,
};

const state = () => initialState;

const mutations = {
    setThreads(state, payload) {
        state.threads = payload
    },
    setCurrentThread(state, payload) {
        state.currentThread = payload
    },
    setCurrentThreadTitle(state, newTitle) {
        state.currentThread = { ...state.currentThread, title: newTitle }
    },
    updateThreadTitleById(state, payload) {
        const { id, title } = payload
        state.threads = state.threads.map((thread) => {
            if (thread.id === id) {
                thread.title = title
            }

            return thread;
        })
    },
    addThread(state, payload) {
        state.threads = [payload, ...state.threads]
    },
    resetFinAgentState(state) {
        Object.assign(state, initialState)
    },
    removeThread(state, id) {
        state.threads = state.threads.filter((c) => c.id !== id)
        
        if (state.currentThread.id === id) {
            state.currentThread = state.threads[0] || initialCurrentThread;
        }
    },
    renameThread(state, payload) {
        const { id, newTitle } = payload
        const foundThreadIndex = state.threads.findIndex((c) => c.id === id)
        const tempThreads = [...state.threads]
        tempThreads[foundThreadIndex].title = newTitle
        state.threads = tempThreads
    },
    setIsThreadsLoading(state, payload) {
        state.isThreadsLoading = payload
    },
    setOpenThreadsMobile(state, payload) {
        state.isOpenThreadsMobile = payload;
    }
};

export default {
    state,
    mutations,
}
