
import PageNavigation from "@/components/header/PageNavigation";

export default {
    name: 'MobileMenu',

    components: {
        PageNavigation,
    },
    props: {
        selectedLanguage: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            isShowedMenu: false,
        }
    },

    computed: {
        isLoggedIn() {
            return !!this.$store.state.account.user
        },
        isFinAgentPage() {
            return this.$route.name == this.$getTranslatedRouteName('fin-agent')
        },
        menuItems() {
            const baseItems = [
                {
                    imgSrcDark: '/svg/icons/chat-bubble-dark.svg',
                    imgSrcLight: '/svg/icons/chat-bubble-light.svg',
                    alt: 'chat-history',
                    displayedText: this.$t('component.finAgent.threads.chatHistory'),
                    isShow: this.isFinAgentPage,
                    onClick: () => this.$store.commit('finAgent/setOpenThreadsMobile', true)
                },
                {
                    imgSrcDark: this.selectedLanguage ? `/flags/${this.selectedLanguage.flag}` : '',
                    imgSrcLight: this.selectedLanguage ? `/flags/${this.selectedLanguage.flag}`: '',
                    alt: 'language-logo',
                    displayedText: this.selectedLanguage ? this.selectedLanguage.name : '',
                    isShow: this.isLoggedIn && this.selectedLanguage,
                    onClick: () => this.$emit('click-language-selector')
                },
            ];

            return baseItems
                .filter(c => c.isShow)
                .map(c => ({ src: this.$vuetify.theme.dark ? c.imgSrcDark : c.imgSrcLight, ...c }));
        }

    },
    
    watch: {
        isShowedMenu(curState) {
            if (curState) {
                this.$store.commit('finAgent/setOpenThreadsMobile', false)
            }
        }
    }
}
