import { render, staticRenderFns } from "./SiteHeader.vue?vue&type=template&id=e9073c04&scoped=true&"
import script from "./SiteHeader.vue?vue&type=script&lang=js&"
export * from "./SiteHeader.vue?vue&type=script&lang=js&"
import style0 from "./SiteHeader.vue?vue&type=style&index=0&id=e9073c04&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9073c04",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Brand: require('/builds/spatz/ui/web/spatz-app/components/Brand.vue').default,Searchbar: require('/builds/spatz/ui/web/spatz-app/components/Searchbar.vue').default,UserMessages: require('/builds/spatz/ui/web/spatz-app/components/UserMessages.vue').default,AuthMenu: require('/builds/spatz/ui/web/spatz-app/components/auth/Menu.vue').default,LocaleSelector: require('/builds/spatz/ui/web/spatz-app/components/LocaleSelector.vue').default,PortfolioTabs: require('/builds/spatz/ui/web/spatz-app/components/portfolio/PortfolioTabs.vue').default})
