import VuexPersistence from 'vuex-persist'

export default ({ store }) => {
    new VuexPersistence({
        /* your options */
        reducer: (state) => {
            // eslint-disable-next-line no-unused-vars
            const { finAgent, ...rest } = state
            return rest;
        }
    }).plugin(store);
}
