const fundamentalFormat = {
    absoluteCurrency: 'absoluteCurrency', // divided by 100 and displayd without decimals
    currency: 'currency', // keep origin value and isplayed with 2 decimals.
    relativeValue: 'relativeValue', // multiplied by 100 and displayed with 2 decimals.
    ratioValue: 'ratioValue', // keep origin value and isplayed with 1 decimals.
}

const keyMetricsSheetSections = [
    {
        title: 'Bewertungskennzahlen',
        rows: [
            { text: 'Marktkapitalisierung', value: 'marketCap', format: fundamentalFormat.absoluteCurrency },
            { text: 'Unternehmenswert', value: 'enterpriseValue', format: fundamentalFormat.absoluteCurrency },
            { text: 'Kurs-Gewinn-Verhältnis (KGV)', value: 'peRatio', format: fundamentalFormat.ratioValue },
            { text: 'Kurs-Umsatz-Verhältnis', value: 'priceToSalesRatio', format: fundamentalFormat.ratioValue },
            { text: 'Kurs-Betriebs-Cashflow-Verhältnis', value: 'pocfratio', format: fundamentalFormat.ratioValue },
            { text: 'Kurs-Freier-Cashflow-Verhältnis', value: 'pfcfRatio', format: fundamentalFormat.ratioValue },
            { text: 'Kurs-Buchwert-Verhältnis', value: 'pbRatio', format: fundamentalFormat.ratioValue },
            { text: 'Kurs-Tangible-Buchwert-Verhältnis', value: 'ptbRatio', format: fundamentalFormat.ratioValue },
            { text: 'Unternehmenswert-Umsatz-Verhältnis', value: 'evToSales', format: fundamentalFormat.ratioValue },
            { text: 'Unternehmenswert-EBITDA-Verhältnis', value: 'enterpriseValueOverEBITDA', format: fundamentalFormat.ratioValue },
            { text: 'Unternehmenswert-Betriebs-Cashflow-Verhältnis', value: 'evToOperatingCashFlow', format: fundamentalFormat.ratioValue }
        ]
    },
    {
        title: 'Rentabilitätskennzahlen',
        rows: [
            { text: 'Gewinnrendite', value: 'earningsYield', format: fundamentalFormat.relativeValue },
            { text: 'Freier Cashflow-Rendite', value: 'freeCashFlowYield', format: fundamentalFormat.relativeValue },
            { text: 'Gewinn je Aktie (EPS)', value: 'netIncomePerShare', format: fundamentalFormat.currency },
            { text: 'Betriebs-Cashflow je Aktie', value: 'operatingCashFlowPerShare', format: fundamentalFormat.currency },
            { text: 'Freier Cashflow je Aktie', value: 'freeCashFlowPerShare', format: fundamentalFormat.currency },
            { text: 'Eigenkapitalrendite', value: 'roe', format: fundamentalFormat.relativeValue },
            { text: 'Rendite auf investiertes Kapital', value: 'roic', format: fundamentalFormat.relativeValue },
            { text: 'Rendite auf Sachwerte', value: 'returnOnTangibleAssets', format: fundamentalFormat.relativeValue }
        ]
    },
    {
        title: 'Liquiditäts- und Effizienzkennzahlen',
        rows: [
            { text: 'Liquiditätsgrad', value: 'currentRatio', format: fundamentalFormat.ratioValue },
            { text: 'Zinsdeckungsgrad', value: 'interestCoverage', format: fundamentalFormat.ratioValue },
            { text: 'Verschuldungsgrad', value: 'debtToEquity', format: fundamentalFormat.relativeValue },
            { text: 'Schuldenquote', value: 'debtToAssets', format: fundamentalFormat.relativeValue },
            { text: 'Netto-Schulden-zu-EBITDA', value: 'netDebtToEBITDA', format: fundamentalFormat.currency },
            { text: 'Betriebskapital', value: 'workingCapital', format: fundamentalFormat.absoluteCurrency }
        ]
    },
    {
        title: 'Dividenden- und Ausschüttungskennzahlen',
        rows: [
            { text: 'Dividendenrendite', value: 'dividendYield', format: fundamentalFormat.relativeValue },
            { text: 'Ausschüttungsquote', value: 'payoutRatio', format: fundamentalFormat.relativeValue }
        ]
    },
    {
        title: 'Cashflow- und Pro-Aktie-Kennzahlen',
        rows: [
            { text: 'Umsatz je Aktie', value: 'revenuePerShare', format: fundamentalFormat.currency },
            { text: 'Bargeld je Aktie', value: 'cashPerShare', format: fundamentalFormat.currency },
            { text: 'Buchwert je Aktie', value: 'bookValuePerShare', format: fundamentalFormat.currency },
            { text: 'Tangible-Buchwert je Aktie', value: 'tangibleBookValuePerShare', format: fundamentalFormat.currency },
            { text: 'Eigenkapital je Aktie', value: 'shareholdersEquityPerShare', format: fundamentalFormat.currency },
            { text: 'Zinsverbindlichkeiten je Aktie', value: 'interestDebtPerShare', format: fundamentalFormat.currency },
            { text: 'Investitionsausgaben je Aktie', value: 'capexPerShare', format: fundamentalFormat.currency }
        ]
    },
    {
        title: 'Wachstums- und Leistungskennzahlen',
        rows: [
            { text: 'Forschung und Entwicklung zu Umsatz Verhältnis', value: 'researchAndDdevelopementToRevenue', format: fundamentalFormat.relativeValue },
            { text: 'Vertriebs- und Verwaltungskosten zu Umsatz Verhältnis', value: 'salesGeneralAndAdministrativeToRevenue', format: fundamentalFormat.relativeValue },
            { text: 'Investitionsausgaben zu Betriebs-Cashflow Verhältnis', value: 'capexToOperatingCashFlow', format: fundamentalFormat.relativeValue },
            { text: 'Investitionsausgaben zu Umsatz Verhältnis', value: 'capexToRevenue', format: fundamentalFormat.relativeValue },
            { text: 'Investitionsausgaben zu Abschreibungen Verhältnis', value: 'capexToDepreciation', format: fundamentalFormat.relativeValue },
            { text: 'Aktienbasierte Vergütungen zu Umsatz', value: 'stockBasedCompensationToRevenue', format: fundamentalFormat.relativeValue },
            { text: 'Immaterielle Vermögenswerte zu Gesamtsumme der Aktiva', value: 'intangiblesToTotalAssets', format: fundamentalFormat.relativeValue }
        ]
    },
    {
        title: 'Aktivitätskennzahlen',
        rows: [
            { text: 'Durchschnittliche Forderungen', value: 'averageReceivables', format: fundamentalFormat.absoluteCurrency },
            { text: 'Durchschnittliche Verbindlichkeiten', value: 'averagePayables', format: fundamentalFormat.absoluteCurrency },
            { text: 'Durchschnittlicher Lagerbestand', value: 'averageInventory', format: fundamentalFormat.absoluteCurrency },
            { text: 'Forderungslaufzeit (DSO) - Tage', value: 'daysSalesOutstanding', format: fundamentalFormat.ratioValue },
            { text: 'Verbindlichkeitenlaufzeit (DPO) - Tage', value: 'daysPayablesOutstanding', format: fundamentalFormat.ratioValue },
            { text: 'Lagerdauer (DIH) - Tage', value: 'daysOfInventoryOnHand', format: fundamentalFormat.ratioValue },
            { text: 'Forderungsumschlag', value: 'receivablesTurnover', format: fundamentalFormat.ratioValue },
            { text: 'Verbindlichkeitenumschlag', value: 'payablesTurnover', format: fundamentalFormat.ratioValue },
            { text: 'Lagerumschlag', value: 'inventoryTurnover', format: fundamentalFormat.ratioValue }
        ]
    },
    {
        title: 'Spezialkennzahlen und Wertindikatoren',
        rows: [
            { text: 'Graham-Zahl', value: 'grahamNumber', format: fundamentalFormat.absoluteCurrency },
            { text: 'Graham-Netto-Netto', value: 'grahamNetNet', format: fundamentalFormat.absoluteCurrency },
            { text: 'Netto-Umlaufvermögen', value: 'netCurrentAssetValue', format: fundamentalFormat.absoluteCurrency },
            { text: 'Sachwert', value: 'tangibleAssetValue', format: fundamentalFormat.absoluteCurrency },
            { text: 'Investiertes Kapital', value: 'investedCapital', format: fundamentalFormat.absoluteCurrency }
        ]
    },
    {
        title: 'Metadaten und Prüfungsinformationen',
        rows: [
            { text: 'Symbol', value: 'symbol' },
            { text: 'Datum', value: 'date' },
            { text: 'Kalenderjahr', value: 'calendarYear' },
            { text: 'Periode', value: 'period' }
        ]
    }
];

const incomeSheetSections = [
    {
        title: 'Umsatz',
        rows: [
            { text: 'Erlöse', value: 'revenue', format: fundamentalFormat.absoluteCurrency },
            { text: 'Herstellungskosten', value: 'costOfRevenue', format: fundamentalFormat.absoluteCurrency },
            { text: 'Bruttogewinn', value: 'grossProfit', format: fundamentalFormat.absoluteCurrency },
            { text: 'Bruttogewinnquote', value: 'grossProfitRatio', format: fundamentalFormat.relativeValue }
        ]
    },
    {
        title: 'Betriebsausgaben',
        rows: [
            { text: 'Forschungs- und Entwicklungskosten', value: 'researchAndDevelopmentExpenses', format: fundamentalFormat.absoluteCurrency },
            { text: 'Allgemeine und Verwaltungskosten', value: 'generalAndAdministrativeExpenses', format: fundamentalFormat.absoluteCurrency },
            { text: 'Vertriebs- und Marketingkosten', value: 'sellingAndMarketingExpenses', format: fundamentalFormat.absoluteCurrency },
            { text: 'Vertriebs-, Allgemeine- und Verwaltungskosten', value: 'sellingGeneralAndAdministrativeExpenses', format: fundamentalFormat.absoluteCurrency },
            { text: 'Sonstige Ausgaben', value: 'otherExpenses', format: fundamentalFormat.absoluteCurrency },
            { text: 'Kosten und Ausgaben', value: 'costsAndExpenses', format: fundamentalFormat.absoluteCurrency },
            { text: 'Abschreibungen und Amortisationen', value: 'depreciationAndAmortization', format: fundamentalFormat.absoluteCurrency },
            { text: 'Betriebsausgaben', value: 'operatingExpenses', customClass: 'total-main-section-row', format: fundamentalFormat.absoluteCurrency },
        ]
    },
    {
        title: 'Betriebsergebnis',
        rows: [
            { text: 'Betriebsergebnis', value: 'operatingIncome', format: fundamentalFormat.absoluteCurrency },
            { text: 'Betriebsergebnismarge', value: 'operatingIncomeRatio', format: fundamentalFormat.relativeValue },
            { text: 'Ergebnis vor Zinsen, Steuern und Abschreibungen', value: 'ebitda', format: fundamentalFormat.absoluteCurrency },
            { text: 'EBITDA-Marge', value: 'ebitDaRatio', format: fundamentalFormat.relativeValue }
        ]
    },
    {
        title: 'Sonstige Erträge und Aufwendungen',
        rows: [
            { text: 'Zinserträge', value: 'interestIncome', format: fundamentalFormat.absoluteCurrency },
            { text: 'Zinsaufwendungen', value: 'interestExpenses', format: fundamentalFormat.absoluteCurrency },
            { text: 'Summe der sonstigen Erträge und Aufwendungen, netto', value: 'totalOtherIncomeExpensesNet', format: fundamentalFormat.absoluteCurrency }
        ]
    },
    {
        title: 'Ergebnis vor Steuern',
        rows: [
            { text: 'Ergebnis vor Steuern', value: 'incomeBeforeTax', format: fundamentalFormat.absoluteCurrency },
            { text: 'Vorsteuerquote', value: 'incomeBeforeTaxRatio', format: fundamentalFormat.relativeValue },
            { text: 'Ertragsteueraufwand', value: 'incomeTaxExpense', format: fundamentalFormat.absoluteCurrency }
        ]
    },
    {
        title: 'Nettogewinn',
        rows: [
            { text: 'Nettogewinn', value: 'netIncome', format: fundamentalFormat.absoluteCurrency },
            { text: 'Nettogewinnquote', value: 'netIncomeRatio', format: fundamentalFormat.relativeValue },
            { text: 'Gewinn je Aktie (EPS)', value: 'eps', format: fundamentalFormat.currency },
            { text: 'Verwässerter Gewinn je Aktie (EPS Diluted)', value: 'epsDiluted', format: fundamentalFormat.currency }
        ]
    },
    {
        title: 'Aktien',
        rows: [
            { text: 'Gewichtete durchschnittliche Anzahl ausstehender Aktien', value: 'weightedAverageShsOut', format: fundamentalFormat.absoluteCurrency },
            { text: 'Gewichtete durchschnittliche Anzahl ausstehender verwässerter Aktien', value: 'weightedAverageShsOutDiluted', format: fundamentalFormat.absoluteCurrency }
        ]
    },
    {
        title: 'Zusätzliche Informationen',
        rows: [
            { text: 'Symbol', value: 'symbol' },
            { text: 'Ende des Geschäftsjahres', value: 'fiscalDateEnding' },
            { text: 'Kalenderjahr', value: 'calendarYear' },
            { text: 'Periode', value: 'period' },
            { text: 'Berichtswährung', value: 'reportedCurrency' },
            { text: 'CIK (Central Index Key)', value: 'cik' },
            { text: 'Einreichungsdatum', value: 'fillingDate' },
            { text: 'Annahmedatum', value: 'acceptedDate' }
        ]
    }
];

const balanceSheetSections = [
    {
        title: 'Vermögenswerte',
        subSections: [
            {
                title: 'Umlaufvermögen',
                rows: [
                    { text: 'Barmittel und Zahlungsmitteläquivalente', value: 'cashAndCashEquivalents', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Kurzfristige Anlagen', value: 'shortTermInvestments', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Barmittel und kurzfristige Anlagen', value: 'cashAndShortTermInvestments', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Sonstige Umlaufvermögenswerte', value: 'otherCurrentAssets', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Summe Umlaufvermögen', value: 'totalCurrentAssets', customClass: 'total-sub-section-row', format: fundamentalFormat.absoluteCurrency },
                ],
            },
            {
                title: 'Anlagevermögen',
                rows: [
                    { text: 'Sachanlagen, netto', value: 'propertyPlantEquipmentNet', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Firmenwert', value: 'goodwill', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Immaterielle Vermögenswerte', value: 'intangibleAssets', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Firmenwert und immaterielle Vermögenswerte', value: 'goodwillAndIntangibleAssets', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Langfristige Anlagen', value: 'longTermInvestments', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Steueransprüche', value: 'taxAssets', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Sonstige langfristige Vermögenswerte', value: 'otherNonCurrentAssets', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Summe Anlagevermögen', value: 'totalNonCurrentAssets', customClass: 'total-sub-section-row', format: fundamentalFormat.absoluteCurrency },
                ],
            }
        ],
        rows: [
            { text: 'Sonstige Vermögenswerte', value: 'otherAssets', format: fundamentalFormat.absoluteCurrency },
            { text: 'Summe Vermögenswerte', value: 'totalAssets', customClass: 'total-main-section-row', format: fundamentalFormat.absoluteCurrency },
        ]
    },
    {
        title: 'Verbindlichkeiten',
        subSections: [
            {
                title: 'Kurzfristige Verbindlichkeiten',
                rows: [
                    { text: 'Verbindlichkeiten aus Lieferungen und Leistungen', value: 'accountPayables', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Kurzfristige Schulden', value: 'shortTermDebt', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Steuerverbindlichkeiten', value: 'taxPayables', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Abgegrenzte Einnahmen', value: 'deferredRevenue', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Sonstige kurzfristige Verbindlichkeiten', value: 'otherCurrentLiabilities', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Summe kurzfristige Verbindlichkeiten', value: 'totalCurrentLiabilities', customClass: 'total-sub-section-row', format: fundamentalFormat.absoluteCurrency }
                ]
            },
            {
                title: 'Langfristige Verbindlichkeiten',
                rows: [
                    { text: 'Langfristige Schulden', value: 'longTermDebt', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Langfristige abgegrenzte Einnahmen', value: 'deferredRevenueNonCurrent', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Langfristige latente Steuerverbindlichkeiten', value: 'deferredTaxLiabilitiesNonCurrent', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Sonstige langfristige Verbindlichkeiten', value: 'otherNonCurrentLiabilities', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Summe langfristige Verbindlichkeiten', value: 'totalNonCurrentLiabilities', customClass: 'total-sub-section-row', format: fundamentalFormat.absoluteCurrency },
                ]
            }
        ],
        rows: [
            { text: 'Sonstige Verbindlichkeiten', value: 'otherLiabilities', format: fundamentalFormat.absoluteCurrency },
            { text: 'Summe Verbindlichkeiten', value: 'totalLiabilities', customClass: 'total-main-section-row', format: fundamentalFormat.absoluteCurrency }
        ]
    },
    {
        title: 'Eigenkapital',
        rows: [
            { text: 'Vorzugsaktien', value: 'preferredStock', format: fundamentalFormat.absoluteCurrency },
            { text: 'Stammaktien', value: 'commonStock', format: fundamentalFormat.absoluteCurrency },
            { text: 'Gewinnrücklagen', value: 'retainedEarnings', format: fundamentalFormat.absoluteCurrency },
            { text: 'Kumuliertes sonstiges Gesamtergebnis', value: 'accumulatedOtherComprehensiveIncomeLoss', format: fundamentalFormat.absoluteCurrency },
            { text: 'Sonstiges Eigenkapital', value: 'otherTotalStockholdersEquity', format: fundamentalFormat.absoluteCurrency },
            { text: 'Summe Eigenkapital', value: 'totalStockholdersEquity', customClass: 'total-sub-section-row', format: fundamentalFormat.absoluteCurrency },
            { text: 'Summe Verbindlichkeiten und Eigenkapital', value: 'totalLiabilitiesAndStockholdersEquity', customClass: 'total-main-section-row', format: fundamentalFormat.absoluteCurrency }
        ]
    },
    {
        title: 'Zusätzliche Informationen',
        subSections: [
            {
                title: 'Schuldeninformationen',
                rows: [
                    { text: 'Summe Anlagen', value: 'totalInvestments', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Gesamtschulden', value: 'totalDebt', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Netto-Schulden', value: 'netDebt', format: fundamentalFormat.absoluteCurrency }
                ]
            },
            {
                title: 'Weitere Informationen',
                rows: [
                    { text: 'Minderheitsanteile', value: 'minorityInterest', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Kapitalleasingverpflichtungen', value: 'capitalLeaseObligations', format: fundamentalFormat.absoluteCurrency },
                    { text: 'Gesamteigenkapital', value: 'totalEquity', format: fundamentalFormat.absoluteCurrency }
                ]
            },
        ]
    },
    {
        title: 'Metadaten und Prüfungsinformationen',
        rows: [
            { text: 'Symbol', value: 'symbol' },
            { text: 'Ende des Geschäftsjahres', value: 'fiscalDateEnding' },
            { text: 'Kalenderjahr', value: 'calendarYear' },
            { text: 'Periode', value: 'period' },
            { text: 'Berichtswährung', value: 'reportedCurrency' },
            { text: 'CIK', value: 'cik' },
            { text: 'Einreichungsdatum', value: 'fillingDate' },
            { text: 'Annahmedatum', value: 'acceptedDate' }
        ]
    }
];

const cashflowSheetSections = [
    {
        title: 'Operative Tätigkeiten',
        rows: [
            { text: 'Nettogewinn', value: 'netIncome', format: fundamentalFormat.absoluteCurrency },
            { text: 'Abschreibungen und Amortisationen', value: 'depreciationAndAmortization', format: fundamentalFormat.absoluteCurrency },
            { text: 'Latente Ertragsteuer', value: 'deferredIncomeTax', format: fundamentalFormat.absoluteCurrency },
            { text: 'Aktienbasierte Vergütungen', value: 'stockBasedCompensation', format: fundamentalFormat.absoluteCurrency },
            { text: 'Veränderung des Umlaufvermögens', value: 'changeInWorkingCapital', format: fundamentalFormat.absoluteCurrency },
            { text: 'Forderungen aus Lieferungen und Leistungen', value: 'accountsReceivables', format: fundamentalFormat.absoluteCurrency },
            { text: 'Verbindlichkeiten aus Lieferungen und Leistungen', value: 'accountsPayables', format: fundamentalFormat.absoluteCurrency },
            { text: 'Sonstiges Umlaufvermögen', value: 'otherWorkingCapital', format: fundamentalFormat.absoluteCurrency },
            { text: 'Sonstige nicht zahlungswirksame Posten', value: 'otherNonCashItems', format: fundamentalFormat.absoluteCurrency },
            { text: 'Nettogeldfluss aus operativen Tätigkeiten', value: 'netCashProvidedByOperatingActivities', format: fundamentalFormat.absoluteCurrency },
            { text: 'Operativer Cashflow', value: 'operatingCashFlow', customClass: 'total-main-section-row', format: fundamentalFormat.absoluteCurrency }
        ]
    },
    {
        title: 'Investitionstätigkeiten',
        rows: [
            { text: 'Investitionen in Sachanlagen', value: 'investmentsInPropertyPlantAndEquipment', format: fundamentalFormat.absoluteCurrency },
            { text: 'Erwerbe, netto', value: 'acquisitionsNet', format: fundamentalFormat.absoluteCurrency },
            { text: 'Kauf von Anlagen', value: 'purchasesOfInvestments', format: fundamentalFormat.absoluteCurrency },
            { text: 'Verkäufe/Fälligkeiten von Anlagen', value: 'salesMaturitiesOfInvestments', format: fundamentalFormat.absoluteCurrency },
            { text: 'Sonstige Investitionstätigkeiten', value: 'otherInvestingActivities', format: fundamentalFormat.absoluteCurrency },
            { text: 'Kapitalaufwand', value: 'capitalExpenditure', format: fundamentalFormat.absoluteCurrency },
            { text: 'Nettoauszahlungen für Investitionstätigkeiten', value: 'netCashUsedForInvestingActivities', customClass: 'total-main-section-row', format: fundamentalFormat.absoluteCurrency },
        ]
    },
    {
        title: 'Finanzierungstätigkeiten',
        rows: [
            { text: 'Schuldenrückzahlung', value: 'debtRepayment', format: fundamentalFormat.absoluteCurrency },
            { text: 'Ausgabe von Stammaktien', value: 'commonStockIssued', format: fundamentalFormat.absoluteCurrency },
            { text: 'Rückkauf von Stammaktien', value: 'commonStockRepurchased', format: fundamentalFormat.absoluteCurrency },
            { text: 'Gezahlte Dividenden', value: 'dividendsPaid', format: fundamentalFormat.absoluteCurrency },
            { text: 'Sonstige Finanzierungstätigkeiten', value: 'otherFinancingActivities', format: fundamentalFormat.absoluteCurrency },
            { text: 'Nettozahlungen/-zuflüsse aus Finanzierungstätigkeiten', value: 'netCashUsedProvidedByFinancingActivities', format: fundamentalFormat.absoluteCurrency }
        ]
    },
    {
        title: 'Auswirkungen von Wechselkursänderungen',
        rows: [
            { text: 'Auswirkungen von Wechselkursänderungen auf Barmittel', value: 'effectOfForexChangesOnCash', format: fundamentalFormat.absoluteCurrency }
        ]
    },
    {
        title: 'Zahlungsmittel am Ende der Periode',
        rows: [
            { text: 'Zahlungsmittel zu Beginn der Periode', value: 'cashAtBeginningOfPeriod', format: fundamentalFormat.absoluteCurrency },
            { text: 'Nettoveränderung der Zahlungsmittel', value: 'netChangeInCash', format: fundamentalFormat.absoluteCurrency },
            { text: 'Zahlungsmittel am Ende der Periode', value: 'cashAtEndOfPeriod', format: fundamentalFormat.absoluteCurrency },
            { text: 'Freier Cashflow', value: 'freeCashFlow', format: fundamentalFormat.absoluteCurrency }
        ]
    },
    {
        title: 'Zusätzliche Informationen',
        rows: [
            { text: 'Symbol', value: 'symbol' },
            { text: 'Ende des Geschäftsjahres', value: 'fiscalDateEnding' },
            { text: 'Kalenderjahr', value: 'calendarYear' },
            { text: 'Periode', value: 'period' },
            { text: 'Berichtswährung', value: 'reportedCurrency' },
            { text: 'CIK', value: 'cik' },
            { text: 'Einreichungsdatum', value: 'fillingDate' },
            { text: 'Annahmedatum', value: 'acceptedDate' }
        ]
    },
];

const earningsSheetSections = [
    {
        title: 'Ergebnisdarstellung',
        rows: [
            { text: 'Quartal', value: 'date' },
            { text: 'Geschätztes Ergebnis', value: 'estimatedEarnings', format: fundamentalFormat.currency },
            { text: 'Tatsächliches Ergebnis', value: 'actualEarningResult', format: fundamentalFormat.currency },
        ]
    },
    {
        title: 'Zusätzliche Informationen',
        rows: [
            { text: 'Symbol', value: 'symbol' },
        ]
    }
]

/**
 * 
 * @param {*} sections: A sheet sections array includes objects have rows, sub-sections
 * @returns an object has shape like { [value]: formatInfo } => e.g. { netChangeInCash: { format: 'currency' } }
 */
const getFormatMapping = (sections) => {
    const formatMapping = {};

    sections.forEach(section => {
        if (section.rows) {
            section.rows.forEach(row => {
                if (row.value) {
                    formatMapping[row.value] = {
                        format: row.format
                    };
                }
            });
        }

        // Process rows in sub sections
        if (section.subSections) {
            section.subSections.forEach(subSection => {
                if (subSection.rows) {
                    subSection.rows.forEach(row => {
                        if (row.value) {
                            formatMapping[row.value] = {
                                format: row.format
                            };
                        }
                    });
                }
            });
        }
    });

    return formatMapping;
}

const balanceSheetFormatMapping = getFormatMapping(balanceSheetSections);
const keyMetricsSheetFormatMapping = getFormatMapping(keyMetricsSheetSections);
const incomeSheetFormatMapping = getFormatMapping(incomeSheetSections);
const cashflowSheetFormatMapping = getFormatMapping(cashflowSheetSections);
const earningsSheetFormatMapping = getFormatMapping(earningsSheetSections);

const fundamentalTimeFrames = {
    '3year': 3,
    '5year': 5,
    '10year': 10,
    '25year': 25,
    'max': -1,
}

export default {
    fundamentalFormat,
    balanceSheetSections,
    keyMetricsSheetSections,
    incomeSheetSections,
    cashflowSheetSections,
    earningsSheetSections,
    balanceSheetFormatMapping,
    keyMetricsSheetFormatMapping,
    incomeSheetFormatMapping,
    cashflowSheetFormatMapping,
    earningsSheetFormatMapping,
    fundamentalTimeFrames,
}
