class FinAgentService {
    lastError;
    lastResponseCode;
    currentRequest = {};

    constructor(axios) {
        this.axios = axios
    }

    async getMessages(threadId) {
        if (this.currentRequest.fetchMessages) {
            await this.currentRequest.fetchMessages.cancel();
        }

        const axiosSource = this.axios.CancelToken.source();
        this.currentRequest.fetchMessages = { cancel: axiosSource.cancel };
        const url = `/api/finagent/thread/${threadId}/messages`;

        return await this
            .axios
            .get(url, { cancelToken: axiosSource.token })
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            });
    }

    async pollMessage(threadId, userMessageId, opt = {}) {
        if (this.currentRequest.pollMessage) {
            await this.currentRequest.pollMessage.cancel();
        }

        const axiosSource = opt.axiosSource || this.axios.CancelToken.source();
        this.currentRequest.pollMessage = { cancel: axiosSource.cancel };
        const url = `/api/finagent/thread/${threadId}/messages/poll/${userMessageId}`;

        return await this
            .axios
            .get(url, { cancelToken: axiosSource.token })
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            });
    }

    async getThreads() {
        if (this.currentRequest.getThreads) {
            await this.currentRequest.getThreads.cancel();
        }

        const axiosSource = this.axios.CancelToken.source();
        this.currentRequest.getThreads = { cancel: axiosSource.cancel };
        const url = '/api/finagent/threads';

        return await this
            .axios
            .get(url, { cancelToken: axiosSource.token })
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            });
    }

    async createMessage(threadId, message) {
        const data = { message };
        const url = `/api/finagent/thread/${threadId}/message`;
        return await this
            .axios
            .post(url, data)
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            });
    }

    async createThread() {
        const url = '/api/finagent/thread';
        return await this
            .axios
            .post(url)
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            });
    }

    async deleteThread(threadId) {
        const url = '/api/finagent/thread/';
        return await this
            .axios
            .delete(url + threadId)
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            });
    }

    async renameThread(threadId, threadTitle) {
        const url = '/api/finagent/thread/';
        const data = {
            threadTitle
        }
        return await this
            .axios
            .put(url + threadId, data)
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            });
    }

    async getProfile() {
        if (this.currentRequest.getProfile) {
            await this.currentRequest.getProfile.cancel();
        }

        const axiosSource = this.axios.CancelToken.source();
        this.currentRequest.getProfile = { cancel: axiosSource.cancel };
        const url = '/api/finagent/profile';

        return await this
            .axios
            .get(url, { cancelToken: axiosSource.token })
            .then((res) => {
                this.lastResponseCode = res.status
                return res.data
            })
            .catch((error) => {
                return this.handleError(error)
            });
    }

    handleError(e) {
        this.lastResponseCode = e.response?.status || e.code;
        this.lastError = e.response?.statusText || e.message;

        throw e;
    }
}

export default FinAgentService
