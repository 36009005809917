
import Alert from "@/components/Alert";
import PrimaryButton from '@/components/shared/PrimaryButton';
import FormatterService from "@/service/FormatterService";
import FinAgentService from '@/service/FinAgentService';
import { backendExceptionHandler } from '@/mixins';

export default {
    name: 'FinAgentProfileDialog',
    mixins: [backendExceptionHandler],

    components: {
        PrimaryButton,
    },

    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isLoading: false,
            errorMessage: '',
            profile: '',
            personalityTags: [],
            headers: [
                { text: this.$t('component.finAgent.profileDialog.tag'), value: 'tag', width: 200 },
                { text: this.$t('component.finAgent.profileDialog.justification'), value: 'justification' },
                { text: this.$t('component.finAgent.profileDialog.created'), value: 'created', align: 'end', width: 160 },
                { text: this.$t('component.finAgent.profileDialog.updated'), value: 'updated', align: 'end', width: 160 },
            ],
        }
    },

    watch: {
        value(state) {
            this.errorMessage = '';
            if (state) {
                this.fetchFinAgentProfile();
            }
        },
    },

    methods: {
        closeModal() {
            this.$emit('input', false);
        },

        async fetchFinAgentProfile() {
            try {
                this.isLoading = true;
                const res = await this.finAgentService.getProfile();
                if (res) {
                    this.profile = res.profile;
                    this.personalityTags = res.personalityTags;
                }
            } catch (error) {
                this.handleBackendException(error, this.$t('common.errors.generalError'));
                this.profile = '';
                this.personalityTags = [];
            } finally {
                this.isLoading = false;
            }
        }
    },

    created() {
        this.formatter = new FormatterService();
        this.finAgentService = new FinAgentService(this.$axios);
    }
}
