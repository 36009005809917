import firebaseApp from "@/firebaseApp";
import * as firebaseAuth from "firebase/auth";
import { doc, getDoc, getFirestore, setDoc, updateDoc } from "firebase/firestore";

const auth = firebaseAuth.getAuth(firebaseApp);
const db = getFirestore(firebaseApp)

const PING_TIMEOUT_MS = 2000
auth.useDeviceLanguage();

class AuthService {
    static resolver = null;
    static secret = null;
    static loginMethod = '';
    static credential = null;
    static maintenanceMessage = '';
    async updateUserData(user) {

        await firebaseAuth.updateProfile(user, {
            displayName: `${user.displayName}`
        }).catch(e => console.log('updateProfile:', e));

        await setDoc(doc(db, 'spatz_user', user.uid), {
            email: user.email,
            firstname: user.displayName.split(" ")[0],
            lastname: user.displayName.split(" ").slice(1).join(" "),
            newsletterOptin: false,
        }).catch(e => console.log('setDoc (spatz_user):', e))
    }

    async googleSignin(gtag) {
        gtag?.event('sp_open_google_signin', { value: 1 });
        const provider = new firebaseAuth.GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/userinfo.email')
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile')
        await firebaseAuth.signInWithPopup(auth, provider).then(function (result) {
            AuthService.credential = firebaseAuth.GoogleAuthProvider.credentialFromResult(result)
            this.updateUserData(result.user)
            gtag?.event('login', {method: 'Google'})
            gtag?.event('conversion', {'send_to': 'AW-11332734530/T2emCK3npJEZEMKc75sq'})
        }).catch(function (error) {
            if (error.code === 'auth/multi-factor-auth-required') {
                AuthService.loginMethod = 'Google'
                AuthService.resolver = firebaseAuth.getMultiFactorResolver(auth, error);
                throw new Error(error.code);
            }
        })
        return AuthService.credential
    }

    async facebookSignin(gtag) {
        gtag?.event('sp_open_facebook_signin', { value: 1 });
        const provider = new firebaseAuth.FacebookAuthProvider();
        provider.setCustomParameters({ 'display': 'popup' })
        await firebaseAuth.signInWithPopup(auth, provider).then(function (result) {
            AuthService.credential = firebaseAuth.FacebookAuthProvider.credentialFromResult(result)
            this.updateUserData(result.user)
            gtag?.event('login', {method: 'Facebook'})
            gtag?.event('conversion', {'send_to': 'AW-11332734530/T2emCK3npJEZEMKc75sq'})
        }).catch((error) => {
            if (error.code === 'auth/multi-factor-auth-required') {
                AuthService.loginMethod = 'Facebook'
                AuthService.resolver = firebaseAuth.getMultiFactorResolver(auth, error);
                throw new Error(error.code);
            }
        })
        return AuthService.credential
    }

    async verify(cred, gtag, $t) {
        await firebaseAuth.signInWithEmailAndPassword(auth, cred.email, cred.password)
            .then(function (userCredential) {
                AuthService.credential = firebaseAuth.EmailAuthProvider.credential(cred.email, cred.password)
                if (!userCredential.user.emailVerified) {
                    this.logout()
                    throw new Error($t('service.auth.verifyEmail'));
                }
                gtag?.event('login', { method: 'EmailAndPassword' });
                return AuthService.credential;
            })
            .catch(function (error) {
                if (error.code === 'auth/multi-factor-auth-required') {
                    AuthService.resolver = firebaseAuth.getMultiFactorResolver(auth, error);
                    AuthService.loginMethod = 'EmailAndPassword';
                    throw new Error(error.code);
                } else if (error.code === 'auth/wrong-password') {
                    throw new Error(error.message);
                }
                throw new Error(error.message);
            });
        return AuthService.credential
    }
    async initializeRecaptchaVerifier() {
        const captchaAuth = await firebaseAuth.getAuth(firebaseApp)
        window.recaptchaVerifier = await new firebaseAuth.RecaptchaVerifier(captchaAuth, "recaptcha-container", {
            "size": "invisible",
        });
    }
    async resetRecapcha() {
        if (window.recaptchaVerifier) {
            window.recaptchaVerifier.clear()
        }
    }


    async completeTwoFaLogin(code, gtag) {
        // eslint-disable-next-line no-case-declarations
        const multiFactorAssertion =
            firebaseAuth.TotpMultiFactorGenerator.assertionForSignIn(
                AuthService.resolver.hints[0].uid,
                code
            );
        try {
            await AuthService.resolver.resolveSignIn(
                multiFactorAssertion
            );
            gtag?.event('login', {method: AuthService.loginMethod})
            gtag?.event('conversion', {'send_to': 'AW-11332734530/T2emCK3npJEZEMKc75sq'})
        } catch (error) {
            throw new Error(error.code)
        }
    }
    async startEnableTwoFaAuthentication() {
        await this.initializeRecaptchaVerifier();
        try {
            const user = auth.currentUser;

            if (!user) {
                throw new Error("No user is currently signed in.");
            }
            const multiFactorSession = await firebaseAuth.multiFactor(auth.currentUser).getSession();
            AuthService.secret = await firebaseAuth.TotpMultiFactorGenerator.generateSecret(
                multiFactorSession
            );
            await this.resetRecapcha()
            return AuthService.secret.generateQrCodeUrl(
                auth.currentUser.email,
                "MoneyPeak"
            );
        } catch (error) {
            console.log(error)
            await this.resetRecapcha()
            throw new Error(error.code);
        }
    }
    async reauthenticateWithCredential(credential) {
        try {
            const response = await firebaseAuth.reauthenticateWithCredential(auth.currentUser, credential)
            return response
        } catch (error) {
            throw new Error(error.code);
        }
    }
    async completeEnableTwoFaAuthentication(code) {
        const multiFactorAssertion = firebaseAuth.TotpMultiFactorGenerator.assertionForEnrollment(
            AuthService.secret,
            code
        );
        try {
            await firebaseAuth.multiFactor(auth.currentUser).enroll(multiFactorAssertion, auth.currentUser.email);
        } catch (error) {
            throw new Error(error.code)
        }
    }
    
    async disableMfa() {
        const user = auth.currentUser;
        const multiFactorUser = firebaseAuth.multiFactor(auth. currentUser);
        await firebaseAuth.multiFactor(user).unenroll(multiFactorUser.enrolledFactors[0])
    }

    async changePassword(passwords, $t) {
        const user = auth.currentUser;

        try {
            await firebaseAuth.reauthenticateWithCredential(
                user, firebaseAuth.EmailAuthProvider.credential(user.email, passwords.current))
        } catch (e) {
            throw new Error($t('service.auth.currentPasswordIncorrect'));
        }
        try {
            await firebaseAuth.updatePassword(user, passwords.new)
        } catch (e) {
            throw new Error($t('service.auth.passwordChangeFailed', { error: e.message }));
        }
    }

    async changeEmail(password, newEmail, $t) {
        const user = auth.currentUser
        await firebaseAuth.reauthenticateWithCredential(
            user, firebaseAuth.EmailAuthProvider.credential(user.email, password))
        await firebaseAuth.verifyBeforeUpdateEmail(user, newEmail).catch(() => {
            throw new Error($t('service.auth.emailUpdateFailed'));
        });
    }

    async signup(userinfo, gtag) {
        const userCredential = await firebaseAuth.createUserWithEmailAndPassword(auth, userinfo.email, userinfo.password)
        await firebaseAuth.sendEmailVerification(userCredential.user)

        await firebaseAuth.updateProfile(userCredential.user, {
            displayName: `${userinfo.firstname} ${userinfo.lastname}`
        }).catch(e => console.log('updateProfile:', e));

        await setDoc(doc(db, 'spatz_user', userCredential.user.uid), {
            email: userinfo.email,
            firstname: userinfo.firstname,
            lastname: userinfo.lastname,
            newsletterOptin: userinfo.newsletterOptin,
            language: userinfo.language,
        }).catch(e => console.log('setDoc (spatz_user):', e))

        gtag?.event('sp_signup', { value: 1 });
        gtag?.event('sign_up', {method: 'Firebase'})

        auth.signOut()
    }

    async resetPassword(email, nextUrl) {
        return await firebaseAuth.sendPasswordResetEmail(auth, email, { url: nextUrl })
    }

    async setNewPassword(actionCode, newPassword, $t) {
        const email = await firebaseAuth.verifyPasswordResetCode(auth, actionCode).catch(() => {
            throw new Error($t('service.auth.passwordResetCodeInvalid'));
        });

        await firebaseAuth.confirmPasswordReset(auth, actionCode, newPassword).catch(() => {
            throw new Error($t('service.auth.passwordResetFailed'))
        });
        firebaseAuth.signInWithEmailAndPassword(auth, email, newPassword)
    }

    async ping(axios, $t) {
        const url = '/api/ping';
        try {
            await axios.get(url, {
                timeout: PING_TIMEOUT_MS,
            });
        } catch (error) {
            if (error.response && error.response.data) {
                const { status, data } = error.response;
                AuthService.maintenanceMessage = data.message
                return { status };
            } else {
                throw new Error($t('service.auth.networkError'));
            }
        }
    }
    getMaintenanceMessage() {
        return AuthService.maintenanceMessage
    }
    logout() {
        auth.signOut()
    }

    async handleVerifyEmail(actionCode, $t) {
        await firebaseAuth.applyActionCode(auth, actionCode).catch(() => {
            throw new Error($t('service.auth.emailVerificationError'));
        });
    }

    async deleteAccount(axios, $t) {
        try {
            const url = `${location.origin}/api/account/delete`
            const jwt = auth.currentUser.accessToken;
            const config = {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            };

            await axios.delete(url, config)
            await auth.currentUser.delete()

        } catch (err) {
            if (err.code === 'auth/requires-recent-login') {
                throw new Error($t('pages.profile.deleteAccount.requireLoginRecently'))
            }
            throw new Error($t('service.auth.accountDeletionFailed', { error: err.message }))
        }
    }

    async updateUserLanguage(languageCode, axios, $t) {
        try {
            const url = `${location.origin}/api/account`;

            const payload = {
                'language': languageCode
            };

            await axios.put(url, payload);
        } catch (err) {
            throw new Error($t('service.auth.avatarUpdateFailed', { error: err.message }));
        }
    }

    getUserInfo() {
        return auth.currentUser
    }

    async updateUserAvatar(axios, t, iconId) {
        try {
            const url = `${location.origin}/api/account`;

            const payload = {
                'customIconId': iconId
            };

            await axios.put(url, payload);
        } catch (err) {
            throw new Error(t('service.auth.languageUpdateFailed', { error: err.message }));
        }
    }

    async getUserFirestoreDoc() {
        const docRef = doc(db, 'spatz_user', auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        return docSnap.data();
    }

    async updateUserName(t, firstname, lastname) {
        try {
            await updateDoc(doc(db, 'spatz_user', auth.currentUser.uid), {
                firstname: firstname,
                lastname: lastname,
            })
        } catch (err) {
            throw new Error(t('service.auth.fullNameUpdateFailed', { error: err.message }));
        }
    }
}

class AuthError extends Error {}

export default new AuthService()
export { AuthError };

